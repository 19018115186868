import gif from "../../../assets/images/token.gif";
import FAHQlogo from "../../../assets/images/FAHQIDentity.png";
import { useCountdown } from "../../hooks/countdown";
import { ContractFunctions, useEthereum } from "../../contexts/ethereum";
import { useAccount, useNetwork } from "wagmi";
import { useChainModal, useConnectModal } from "@rainbow-me/rainbowkit";
import { toast } from "react-toastify";
import { useState } from "react";
import { FaSpinner } from "react-icons/fa";
import "./Identity.css";
import { BigNumber } from "ethers";

export const Identity = ({
  IDentityRef,
}: {
  IDentityRef?: React.RefObject<HTMLDivElement>;
}) => {
  const {
    contractData,
    IdentitySigner,
    IdentityContract,
    chainId,
    FAHQSigner,
  } = useEthereum();
  const { isConnected, isConnecting } = useAccount();
  const { chain } = useNetwork();
  const { openChainModal } = useChainModal();
  const { openConnectModal } = useConnectModal();

  const [range, setRange] = useState<number>(1);
  const [minting, setMinting] = useState<boolean>(false);
  const [approving, setApproving] = useState<boolean>(false);

  const handleApprove = async () => {
    if (chain?.id != chainId) {
      return openChainModal && openChainModal();
    }
    setApproving(true);
    try {
      const receipt = await FAHQSigner?.setApprovalForAll(
        IdentityContract.addressOrName,
        true
      );

      await receipt.wait();
      setApproving(false);
    } catch (e: any) {
      console.log(e);
      toast.error(e?.data?.data?.reason || "Transaction Failed");
      setApproving(false);
    }
  };

  const handleMint = async () => {
    if (chain?.id != chainId) {
      return openChainModal && openChainModal();
    }
    setMinting(true);
    try {
      const receipt = await IdentitySigner?.mint(range);
      await receipt.wait();

      setMinting(false);
    } catch (e: any) {
      console.log({ e });
      setMinting(false);
      toast.error(e?.data?.data?.reason || e?.reason || "Transaction Failed");
    }
  };

  const canMint: BigNumber =
    contractData?.data?.[ContractFunctions.token4Balance];
  const state = contractData?.data?.[ContractFunctions.identityMintState];
  const totalSupply = contractData?.data?.[ContractFunctions.identitySupply];

  console.log(totalSupply);
  return (
    <div
      className="page-container identity-page"
      style={{ paddingBottom: "20px" }}
    >
      <img src={FAHQlogo} alt="FAHQ" className="identity-image" />

      <div className="row identity-top-section">
        <img src={gif} alt={"FAHQIDentity"} className="identity-gif" />
        <div className="col identity-text">
          <h1 className="identity-title">MORE THAN AN AVATAR</h1>
          <p className="identity-paragraph">
            This collection of 15,000 NFTs feature fun and clean traits, each
            with different rarity levels.
          </p>
          <p className="identity-paragraph">
            There are fifteen &quot;1 of 1&quot; speacial ultra-rare NFTs in the
            collection.
          </p>
          <p className="identity-paragraph">
            Each FAHQ<sup>®</sup> IDentity NFT is an access token to our private
            online community, launching in early 2023, as well as access to our
            limited-edition drops, collections, and collaborations.
          </p>
          <p className="identity-paragraph">
            Additionally, holders will be able to access a 3D metaverse version
            of their FAHQ<sup>®</sup> IDentity after they are completed for the
            Otherside.
          </p>
        </div>
      </div>

      <CountDown />

      <div className="identity-price">Mint Price: Ξ0</div>

      {state >= 0 && (
        <div className="identity-status-container">
          <span className="identity-mint-status">Mint Status{` `}</span>
          <span>{state ? "Live" : "Off"}</span>
        </div>
      )}
      {canMint?.toNumber() >= 0 && (
        <div className="identity-remaining">
          FAHQ<sup>®</sup> Tokens: {canMint?.toString()}
        </div>
      )}

      <div className="IDentity-button-container" ref={IDentityRef} tabIndex={0}>
        {isConnected ? (
          contractData?.data?.[ContractFunctions.isApprovedForAll] ? (
            <>
              {canMint?.toNumber() > 1 && (
                <div className="identity-range-container">
                  {
                    <input
                      type={"range"}
                      className="token-range mint"
                      min={1}
                      max={canMint.toNumber()}
                      value={range}
                      onChange={(e) => setRange(Number(e.target.value))}
                    />
                  }
                  <span className="token-range-text">{range}</span>
                </div>
              )}
              {
                <button
                  className="identity-button identity-mint"
                  disabled={!state || !canMint?.toNumber() || minting}
                  onClick={handleMint}
                >
                  {minting ? (
                    <>
                      <FaSpinner className="icon-spin" /> Minting
                    </>
                  ) : (
                    <>Mint</>
                  )}
                </button>
              }
            </>
          ) : (
            <button
              className="identity-button identity-approve"
              onClick={handleApprove}
              disabled={approving}
            >
              {approving ? (
                <>
                  <FaSpinner className="icon-spin" /> Approving
                </>
              ) : (
                <>Approve</>
              )}
            </button>
          )
        ) : (
          <div
            className="identity-button identity-connect"
            onClick={openConnectModal}
          >
            {isConnecting ? (
              <>
                <FaSpinner className="icon-spin" /> Connecting
              </>
            ) : (
              <>Connect Wallet</>
            )}{" "}
          </div>
        )}
      </div>

      {totalSupply && (
        <div className="identity-remaining">
          {15000 - totalSupply?.toNumber()}
          /15000 remaining
        </div>
      )}

      <div className="identity-mint-explanation">
        To mint, you will need to burn a FAHQ<sup>®</sup> IDentity Mint Pass,
        which you receive when you mint a FAHQ<sup>®</sup> Access Plus NFT.
      </div>

      <a
        className="terms"
        href="https://www.fahq.com/pages/nft-terms-conditions"
        target="_blank"
        rel="noreferrer"
      >
        FAHQ<sup>®</sup> NFT Terms & Conditions
      </a>
    </div>
  );
};

const CountDown = () => {
  const timer = useCountdown(1661191106465);
  return (
    <div className="timers-container">
      <p>
        Mint Countdown {timer[0].toString().padStart(2, "0")}:
        {timer[1].toString().padStart(2, "0")}:
        {timer[2].toString().padStart(2, "0")}:
        {timer[3].toString().padStart(2, "0")}
      </p>
    </div>
  );
};

import "./Token.css";
import { AiFillCheckCircle } from "react-icons/ai";
import { Children, useState } from "react";
import { ContractFunctions, useEthereum } from "../../contexts/ethereum";
import { getStatus, getTokenData } from "../../../utils/contractData";
import { useAccount, useNetwork } from "wagmi";
import { useChainModal, useConnectModal } from "@rainbow-me/rainbowkit";
import { BigNumber, ethers } from "ethers";
import { toast } from "react-toastify";
import { FaSpinner } from "react-icons/fa";

interface IToken {
  imgSrc: string;
  title: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  >;
  children: React.ReactNode[] | React.ReactNode;
  saleNumber: number;
  IDentityRef?: React.RefObject<HTMLDivElement>;
}
export const Token = ({
  imgSrc,
  title,
  children,
  saleNumber,
  IDentityRef,
}: IToken) => {
  const childrenArray = Children.toArray(children);
  const { contractData, FAHQSigner, hexProofs, chainId } = useEthereum();
  const { isConnected, isConnecting } = useAccount();
  const { chain } = useNetwork();
  const { openConnectModal } = useConnectModal();
  const { openChainModal } = useChainModal();
  const [range, setRange] = useState<number>(1);
  const [minting, setMinting] = useState<boolean>(false);

  const status =
    saleNumber &&
    contractData?.data &&
    contractData.data[ContractFunctions.getSaleStates] &&
    contractData.data[ContractFunctions.getSaleStates][saleNumber - 1];

  const tokenData =
    saleNumber &&
    contractData?.data &&
    contractData.data[getTokenData(saleNumber)];

  const totalSupply =
    saleNumber &&
    contractData?.data &&
    contractData.data[ContractFunctions.getTotalSupply] &&
    contractData.data[ContractFunctions.getTotalSupply][saleNumber - 1];

  const canMintAllow =
    saleNumber &&
    status &&
    contractData?.data?.[ContractFunctions.amountMinted] &&
    (tokenData.maxMintAllowlist as BigNumber)
      ?.sub(
        contractData?.data[ContractFunctions.amountMinted][
          (saleNumber - 1) * 2 + status - 1
        ]
      )
      .toNumber();

  const canMintPublic =
    saleNumber &&
    status &&
    contractData?.data?.[ContractFunctions.amountMinted] &&
    (tokenData.maxMintPublic as BigNumber)
      ?.sub(
        contractData?.data[ContractFunctions.amountMinted][
          (saleNumber - 1) * 2 + status - 1
        ]
      )
      .toNumber();

  const canMint = status === 1 ? canMintAllow : canMintPublic;

  const handleMint = async () => {
    console.log("chain", chain?.id, chainId);
    if (chain?.id != chainId) {
      return openChainModal && openChainModal();
    }
    setMinting(true);
    try {
      const value = tokenData.price.mul(range);
      const receipt = await FAHQSigner?.mint(
        saleNumber,
        range,
        hexProofs[(saleNumber || 1) - 1],
        { value }
      );
      await receipt.wait();
      setMinting(false);
    } catch (e: any) {
      console.log({ e });
      toast.error(e?.data?.data?.reason || e?.reason || "Transaction Failed");
      setMinting(false);
    }
  };

  const handleIDentity = () => {
    console.log(IDentityRef);
    IDentityRef?.current?.focus();
  };

  return (
    <div className="token">
      <video className="token-img" autoPlay loop muted>
        <source src={imgSrc} />
      </video>
      {title}
      {childrenArray.map((val, i) => {
        return (
          <div key={i} className={"token-list-item"}>
            <AiFillCheckCircle className="check-icon" /> {val}
          </div>
        );
      })}
      {saleNumber === 4 && (
        <button className="token-button mint" onClick={handleIDentity}>
          Mint IDentity
        </button>
      )}
      {saleNumber < 4 && (
        <div className="token-mint">
          {status !== null && (
            <div className="status-container">
              <p className="token-status-title">Status</p>
              <p className={`token-status`}>{getStatus(status)}</p>
            </div>
          )}

          {tokenData?.price && (
            <>
              <p className="token-price">
                Mint Price: Ξ
                {
                  +(
                    range * Number(ethers.utils.formatEther(tokenData.price))
                  ).toFixed(3)
                }
              </p>
            </>
          )}
          {isConnected ? (
            <>
              {tokenData &&
                status >= 0 &&
                (canMintAllow >= 0 ? (
                  <div
                    className="range-container"
                    style={{
                      visibility:
                        getStatus(status) === "Off" || !canMint
                          ? "hidden"
                          : undefined,
                    }}
                  >
                    <input
                      type={"range"}
                      className="token-range"
                      min={1}
                      max={canMint}
                      value={range}
                      onChange={(e) => setRange(Number(e.target.value))}
                    />
                    <span className="token-range-text">{range}</span>
                  </div>
                ) : (
                  <div>You minted the max</div>
                ))}
              <button
                className={`token-button mint`}
                disabled={
                  getStatus(status) === "Off" ||
                  (status == 1 && !canMintAllow) ||
                  (status === 2 && !canMintPublic) ||
                  minting
                }
                onClick={() => handleMint()}
              >
                {minting ? (
                  <>
                    <FaSpinner className="icon-spin" /> Minting
                  </>
                ) : (
                  <>Mint</>
                )}
              </button>
            </>
          ) : (
            <div className="token-button connect" onClick={openConnectModal}>
              {isConnecting ? (
                <>
                  <FaSpinner className="icon-spin" /> Connecting
                </>
              ) : (
                <>Connect Wallet</>
              )}
            </div>
          )}

          {tokenData?.maxSupply && (
            <p className="token-supply">
              {totalSupply.toString()}/{tokenData.maxSupply.toString()} Minted
            </p>
          )}
        </div>
      )}
    </div>
  );
};

import "./MintPage.css";
import FAHQlogo from "../../../assets/images/FAHQ.png";
import { useCountdown } from "../../hooks/countdown";
import { Token } from "../../components/Token/Token";
import movie1 from "../../../assets/movies/1.mp4";
import movie2 from "../../../assets/movies/2.mp4";
import movie3 from "../../../assets/movies/3.mp4";
import movie4 from "../../../assets/movies/4.mp4";

const MintPage = ({
  IDentityRef,
}: {
  IDentityRef?: React.RefObject<HTMLDivElement>;
}) => {
  return (
    <div className="page-container">
      <img src={FAHQlogo} alt="FAHQ" className="mint-image" />
      <h1 className="header">
        Membership Access to Limited-Edition Drops, Collections, and
        Collaborations
      </h1>
      <h2 className="header2">How Does This Work?</h2>
      <div className="list-container">
        <ol className="ordered-list">
          <li className="list-item">
            Mint a FAHQ<sup>®</sup> NFT
          </li>
          <li className="list-item">
            Follow our Twitter, Instagram, Facebook, and TikTok to know when we
            release new exclusive holder-only drops, collections,
            collaborations, offers, discounts, etc. Your NFT is an access token
            for all of this and more!
          </li>
        </ol>
      </div>
      <CountDown />
      <div className="tokens-containers">
        <Token
          imgSrc={movie1}
          title={
            <p className="token-title">
              FAHQ<sup>®</sup> Access
            </p>
          }
          saleNumber={1}
        >
          <span className="list-item">
            Access to limited-edition drops, collections, and collaborations
          </span>
        </Token>
        <Token
          imgSrc={movie2}
          title={
            <p className="token-title">
              FAHQ<sup>®</sup> Access Plus
            </p>
          }
          saleNumber={2}
        >
          <span className="list-item">
            Access to limited-edition drops, collections, and collaborations
          </span>
          <span className="list-item">Random metaverse wearable airdrops</span>
          <span className="list-item">
            Includes the FAHQ<sup>®</sup> IDentity Mint Pass NFT, which can be
            burned to mint a FAHQ<sup>®</sup> IDentity free, giving you access
            to our online community
          </span>
        </Token>
        <Token
          imgSrc={movie3}
          title={
            <p className="token-title">
              FAHQ<sup>®</sup> VIP
            </p>
          }
          saleNumber={3}
        >
          <span className="list-item">
            Access to limited-edition drops, collections, and collaborations
          </span>
          <span className="list-item">
            Ability to claim free VIP-only offers (just pay shipping)
          </span>
          <span className="list-item">Access to VIP-only future perks</span>
        </Token>
        <Token
          imgSrc={movie4}
          title={
            <p className="token-title">
              FAHQ<sup>®</sup> IDentity Mint Pass
            </p>
          }
          saleNumber={4}
          IDentityRef={IDentityRef}
        >
          <span className="list-item">
            Burn to mint a FAHQ<sup>®</sup> IDentity NFT free
          </span>
          <span className="list-item">
            FAHQ<sup>®</sup> IDentity NFTs are our avatars/profile picture
            collection that provides to our private community launching in early
            2023
          </span>
          <span className="list-item">
            FAHQ<sup>®</sup> IDentity holders will be able to access 3D
            metaverse versions after they are completed for the Otherside
          </span>
          <span className="list-item">
            Recieve one FAHQ<sup>®</sup> IDentity Mint Pass for every FAHQ
            <sup>®</sup> Access Plus NFT minted
          </span>
        </Token>
      </div>
    </div>
  );
};

const CountDown = () => {
  const allowTimer = useCountdown(1660586306465);
  const publicTimer = useCountdown(1661191106465);

  return (
    <div className="timers-container">
      <p>
        Allow List Mint in {allowTimer[0].toString().padStart(2, "0")}:
        {allowTimer[1].toString().padStart(2, "0")}:
        {allowTimer[2].toString().padStart(2, "0")}:
        {allowTimer[3].toString().padStart(2, "0")}
      </p>
      <p>
        Public Mint in {publicTimer[0].toString().padStart(2, "0")}:
        {publicTimer[1].toString().padStart(2, "0")}:
        {publicTimer[2].toString().padStart(2, "0")}:
        {publicTimer[3].toString().padStart(2, "0")}
      </p>
    </div>
  );
};
export default MintPage;

import "./footer.css";
import { ReactComponent as Logo } from "../../../assets/images/atlasIcon.svg";
// import name from "../../../assets/images/atlasName.svg";

const Footer = (): JSX.Element => {
  return (
    <nav className="footer">
      <a
        className="logo-container"
        href="https://www.atlascorp.io/"
        target={"_blank"}
        rel="noreferrer"
      >
        <div className="footer-text">Developed by</div>
        <Logo className="atlas-icon" />
        {/* <Name className="atlas-name" /> */}
      </a>
    </nav>
  );
};

export default Footer;

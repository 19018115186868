import MintPage from "./common/pages/MintPage/MintPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";

import { Identity } from "./common/pages/IdentityPage/Identity";
import { useRef } from "react";
import Footer from "./common/components/Footer/Footer";

function App() {
  // const { chain } = useNetwork();

  // const { openConnectModal } = useConnectModal();
  const IDentityRef = useRef<HTMLDivElement>(null);
  return (
    <BrowserRouter>
      <div className="App">
        <div className="app-container">
          <Routes>
            {/* <Navbar /> */}
            <Route
              path="/"
              element={
                <>
                  <MintPage IDentityRef={IDentityRef} />
                  <Identity IDentityRef={IDentityRef} />
                  <Footer />
                </>
              }
            />
            <Route path="/identity" element={<Identity />} />
            {/* <AccountBar /> */}
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;

import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Contract } from "ethers";
import { toast } from "react-toastify";
import { useAccount, useContractReads, useSigner } from "wagmi";
import { addressConcat } from "../../utils/address_utils";
import whiteLists from "../constants/whiteLists.json";
import FAHQContractInfo from "../../contracts/FAHQ.json";
import IdentityContractInfo from "../../contracts/FAHQIDentity.json";
import { keccak256 } from "ethers/lib/utils";
import MerkleTree from "merkletreejs";

export interface IEthereumContextValues {
  contractData: any;
  FAHQSigner?: Contract;
  IdentitySigner?: Contract;
  hexProofs: string[][];
  IdentityContract: { addressOrName: string };
  chainId: number;
}

export enum ContractFunctions {
  getSaleStates,
  getTotalSupply,
  tokenData1,
  tokenData2,
  tokenData3,
  amountMinted,
  token4Balance,
  isApprovedForAll,
  identitySupply,
  identityMintState,
}

export const EthereumContext = createContext({} as IEthereumContextValues);

export const useEthereum = () => {
  return useContext(EthereumContext);
};

export const EthereumProvider = ({
  children,
  dev,
}: {
  children: React.ReactNode;
  dev?: "dev" | "rinkeby" | "mainnet";
}): JSX.Element => {
  const { ethereum } = window;
  const [hexProofs, setHexProofs] = useState<string[][]>([]);
  const { address } = useAccount();
  const { data: signer } = useSigner();

  const chainId: number = dev === "dev" ? 1337 : dev === "mainnet" ? 1 : 4;

  useEffect(() => {
    if (ethereum) {
      ethereum.on("accountsChanged", (accounts: any) => {
        if (accounts) {
          console.log("changed acccount", accounts[0]);
          toast(`Changed Account: \n ${addressConcat(accounts[0])}`, {
            style: { color: "#7F3B2F" },
          });
        }
      });
    }
    return () => {
      ethereum.removeListener("accountsChanged");
    };
  }, []);

  const FAHQContract = {
    addressOrName:
      dev == "dev"
        ? FAHQContractInfo.networks[1337].address
        : dev === "rinkeby"
        ? FAHQContractInfo.networks[4].address
        : "",
    contractInterface: FAHQContractInfo.abi,
  };

  const IdentityContract = {
    addressOrName:
      dev == "dev"
        ? IdentityContractInfo.networks[1337].address
        : dev === "rinkeby"
        ? IdentityContractInfo.networks[4].address
        : "",
    contractInterface: IdentityContractInfo.abi,
  };

  const contractData = useContractReads({
    contracts: [
      {
        ...FAHQContract,
        functionName: "getSaleStates",
        chainId,
      },
      {
        ...FAHQContract,
        functionName: "getTotalSupply",
        args: [],
        chainId,
      },
      {
        ...FAHQContract,
        functionName: "tokenData",
        args: [1],
        chainId,
      },
      {
        ...FAHQContract,
        functionName: "tokenData",
        args: [2],
        chainId,
      },
      {
        ...FAHQContract,
        functionName: "tokenData",
        args: [3],
        chainId,
      },
      {
        ...FAHQContract,
        functionName: "getAmountMintedPerTokenId",
        args: [address],
        chainId,
      },
      {
        ...FAHQContract,
        functionName: "balanceOf",
        args: [address, 4],
        chainId,
      },
      {
        ...FAHQContract,
        functionName: "isApprovedForAll",
        args: [address, IdentityContract.addressOrName],
        chainId,
      },
      {
        ...IdentityContract,
        functionName: "totalSupply",
        chainId,
      },
      {
        ...IdentityContract,
        functionName: "mintState",
        chainId,
      },
    ],
    watch: true,
  });

  const FAHQSigner = useMemo(() => {
    if (signer)
      return new Contract(
        dev === "dev"
          ? FAHQContractInfo.networks[1337].address
          : dev === "rinkeby"
          ? "0x5B7AE3e146A76777819796769dd30Cd791367BdC"
          : "",
        FAHQContractInfo.abi,
        signer
      );
  }, [signer]);

  const IdentitySigner = useMemo(() => {
    if (signer)
      return new Contract(
        dev === "dev"
          ? IdentityContractInfo.networks[1337].address
          : dev === "rinkeby"
          ? IdentityContractInfo.networks[4].address
          : "",
        IdentityContractInfo.abi,
        signer
      );
  }, [signer]);

  useEffect(() => {
    if (address) {
      whiteLists.forEach((whitelist, index) => {
        const leaves = whitelist.map((account) => keccak256(account));
        const tree = new MerkleTree(leaves, keccak256, { sortPairs: true });
        if (!tree) return;
        const hexProof = tree.getHexProof(keccak256(address));
        setHexProofs((prevProof) => {
          const proofs = prevProof;
          proofs[index] = hexProof;
          return proofs;
        });
      });
    }
  }, [address]);

  console.log(contractData);
  return (
    <EthereumContext.Provider
      value={{
        contractData,
        FAHQSigner,
        IdentitySigner,
        hexProofs,
        IdentityContract,
        chainId,
      }}
    >
      {children}
    </EthereumContext.Provider>
  );
};

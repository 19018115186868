import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { EthereumProvider } from "./common/contexts/ethereum";
import "react-toastify/dist/ReactToastify.css";
import "@rainbow-me/rainbowkit/styles.css";
import { ToastContainer } from "react-toastify";
import { chain, configureChains, createClient, WagmiConfig } from "wagmi";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { publicProvider } from "wagmi/providers/public";

const { chains, provider } = configureChains(
  [
    // chain.mainnet,
    chain.rinkeby,
    // chain.localhost,
  ],
  [
    jsonRpcProvider({
      rpc: (chain) =>
        chain.id === 1337
          ? { http: "http://localhost:8545" }
          : chain.id === 1
          ? {
              http: "https://cold-wispy-firefly.quiknode.pro/efe16c65e7bdd497c2a34cdef175db8385bed823/",
            }
          : {
              http: "https://polished-withered-river.rinkeby.quiknode.pro/217cbb0fe5615e6c2515849dba7f4a34da425c79",
            },
    }),
    publicProvider(),
  ]
);

const { connectors } = getDefaultWallets({
  appName: "Aquarium Treasury Dapp",
  chains,
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

console.log(chains);
ReactDOM.render(
  <React.StrictMode>
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider
        chains={chains}
        coolMode
        showRecentTransactions={true}
      >
        <EthereumProvider dev={"rinkeby"}>
          <App />
          <ToastContainer position="bottom-right" />
        </EthereumProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { ContractFunctions } from "../common/contexts/ethereum";

export const getStatus = (status: number) => {
  switch (status) {
    case 0:
      return "Off";
    case 1:
      return "Allow List";
    case 2:
      return "Public";
  }
};

export const getTokenData = (saleNumber: number) => {
  switch (saleNumber) {
    case 1:
      return ContractFunctions.tokenData1;
    case 2:
      return ContractFunctions.tokenData2;
    case 3:
      return ContractFunctions.tokenData3;
    default:
      return 100;
  }
};
